









































































import Vue from 'vue'
import wAxios from '@/plugins/w/axios'

const api = {
  sort:         (data)    => wAxios.post_auth(`/v1/group-admin/groups/sort`, data),
  groups:       (params?) => wAxios.get_auth(`/v1/group-admin/groups`, { params }),
  deleteGroup:  (id)      => wAxios.delete_auth(`/v1/group-admin/groups/${id}`)
}

export default {
  
  components: {
    'vue-infinite-loading':       () => import('vue-infinite-loading'),
    'draggable':                  () => import('vuedraggable'),
    'z-list-header':              () => import('@/plugins/app@components/list-header/z-list-header.vue'),
    'z-loader':                   () => import('@/plugins/app@components/loader/z-loader.vue'),
    'a-student-group-share':      () => import('../_components/student-group-share-modal/a-student-group-share.vue'),
    'a-student-group-duplicate':  () => import('../_components/student-group-duplicate-modal/a-student-group-duplicate.vue')
  },

  data() {
    return {
      currentPage: +this.$route.query?.page || 1,
      totalGroups: null,
      perPage: 15,
      loadingGroups: false,
      groups: [],
      selectedGroup: null
    }
  },

  async mounted() {
    await this.updateGroups()
  },

  methods: {
    async updateGroups() {
      this.loadingGroups = true
      try {
        await this.loadGroups()
      } catch(err) {
        this.$wToast.error(err)
      } finally {
        this.loadingGroups = false
      }
    },

    async loadGroups(event?) {
      const page = (event != null && event != undefined) ? event : this.currentPage
      try {
        const { data } = await api.groups({ page, limit: this.perPage })
        this.groups.splice(page * this.perPage - this.perPage, page * this.perPage, ...data.data)
        this.totalGroups = data.meta?.total
        if (data.data.length)
          this.currentPage = page
      } catch(err) {
        this.$wToast.error(err)
      }
    },

    async loadMoreGroups($state) {
      if (this.totalGroups == this.groups.length) {
        $state.loaded()
        $state.complete()
        return
      }

      await this.loadGroups(++this.currentPage)
      $state.loaded()
    },

    async deleteGroup(i) {
      await this.selectGroup(i)
      try {
        const confirm = await this.$bvModal.msgBoxConfirm('Naozaj chcete vymazať túto skupinu?', {
          title: 'Vymazať skupinu?',
          okTitle: 'Vymazať',
          okVariant: 'a-primary',
          cancelTitle: 'Zrušiť',
          cancelVariant: 'a-primary--outline',
          buttonSize: 'sm',
          hideHeaderClose: false,
        })

        if (confirm) {
          await api.deleteGroup(this.selectedGroup.id)
          await this.reloadGroups()
        }
      } catch(err) {
        this.$wToast.error(err)
      }
    },

    async reloadGroups() {
      // reset infiniteLoading => if all groups pages were loaded before reload, 
      // it would not work anymore if you duplicated groups (added new to list), infinite would not trigger -> because it would be in completed state
      this.$refs.infiniteLoading.stateChanger.reset()
      const pagesCount = this.currentPage
      for(let i = 1; i <= pagesCount; i++) {  
        await this.loadGroups(i)
      }
    },

    async shareGroup(i) {
      await this.selectGroup(i)
      this.$bvModal.show('ShareGroupModal')
    },
    
    async duplicateGroup(i) {
      await this.selectGroup(i)
      this.$bvModal.show('DuplicateGroupModal')
    },

    async selectGroup(i) {
      this.selectedGroup = this.groups[i]
      await Vue.nextTick()
    },

    async sort() {
      try {
        await api.sort({ sorted_ids: this.groups.map(group => group.id) })
      } catch(err) {
        this.$wToast.error(err)
      }
    }
  }
}
